import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'

const Thanks = () => (
  <Layout>
    <Hero
      title="Veranstaltungen bei Colenio"
      subtitle="Registrieren Sie sich für unsere kommenden Veranstaltungen."
    />
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Vielen Dank für Ihre Registrierung!</h1>
          <p>Sie erhalten in Kürze eine Bestätigunsmail von uns.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Thanks
